exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__container___1_pn_ {\n  height: inherit; }\n\n.styles__home___3viHg {\n  width: 100%;\n  height: 100%; }\n\n.styles__content___1IZei {\n  position: absolute;\n  left: 0;\n  bottom: 0;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: calc(100% - 40px); }\n", ""]);

// exports
exports.locals = {
	"container": "styles__container___1_pn_",
	"home": "styles__home___3viHg",
	"content": "styles__content___1IZei"
};