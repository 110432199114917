import React from 'react';
import styles from './styles.scss';

const GaTriangles = () => (
  <article>

    <h1>Particle Remix: Real-time visual sampling into particle fields</h1>

    <p className={styles.textBlock}>
      A program that samples in real-time from a section of a computer monitor.
      The resulting pixel data is used to create a particle field.  Many parameters
      can be controlled including size, rotation, time to live, velocity, etc.
      The application also features motion detection and texture mapping.
    </p>

    <p className={styles.textBlock}>
      <span>
        Source:
      </span>
      <span>
        <a href="https://github.com/0la0/particleRemix">github.com/0la0/particleRemix</a>
      </span>
    </p>

    <img className={styles.projectImage} src="assets/images/projects/particleRemix/01.png" />
    <p className={styles.subtext}>Figure 6ae0f29.</p>

    <img className={styles.projectImage} src="assets/images/projects/particleRemix/02.png" />
    <p className={styles.subtext}>Figure 6ae0f29.</p>

    <img className={styles.projectImage} src="assets/images/projects/particleRemix/03.png" />
    <p className={styles.subtext}>Figure 6ae0f29.</p>

    <img className={styles.projectImage} src="assets/images/projects/particleRemix/04.png" />
    <p className={styles.subtext}>Figure 6ae0f29.</p>

    <img className={styles.projectImage} src="assets/images/projects/particleRemix/05.png" />
    <p className={styles.subtext}>Figure 6ae0f29.</p>

    <img className={styles.projectImage} src="assets/images/projects/particleRemix/06.png" />
    <p className={styles.subtext}>Figure 6ae0f29.</p>

    <img className={styles.projectImage} src="assets/images/projects/particleRemix/07.png" />
    <p className={styles.subtext}>Figure 6ae0f29.</p>

    <img className={styles.projectImage} src="assets/images/projects/particleRemix/08.png" />
    <p className={styles.subtext}>Figure 6ae0f29.</p>

    <img className={styles.projectImage} src="assets/images/projects/particleRemix/09.png" />
    <p className={styles.subtext}>Figure 6ae0f29.</p>

    <img className={styles.projectImage} src="assets/images/projects/particleRemix/10.png" />
    <p className={styles.subtext}>Figure 6ae0f29.</p>

    <img className={styles.projectImage} src="assets/images/projects/particleRemix/11.png" />
    <p className={styles.subtext}>Figure 6ae0f29.</p>

  </article>
);

export default GaTriangles;
