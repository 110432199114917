exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__menuButton___3lczn {\n  position: absolute;\n  left: 10px;\n  top: 10px; }\n\n.styles__backButton___7UJJw {\n  position: absolute;\n  left: 60px;\n  top: 10px; }\n\n.styles__aboutButton___52gZu {\n  position: absolute;\n  right: 10px;\n  top: 13px;\n  cursor: pointer;\n  width: 50px;\n  height: 30px; }\n", ""]);

// exports
exports.locals = {
	"menuButton": "styles__menuButton___3lczn",
	"backButton": "styles__backButton___7UJJw",
	"aboutButton": "styles__aboutButton___52gZu"
};