exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__svg___3x5LQ {\n  width: 100%;\n  height: 100%;\n  cursor: pointer; }\n", ""]);

// exports
exports.locals = {
	"svg": "styles__svg___3x5LQ"
};