exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__container___1l0SO {\n  position: absolute;\n  right: 30px;\n  top: 60px;\n  width: calc(100% - 60px);\n  max-height: calc(100% - 90px);\n  max-width: 560px;\n  background-color: #D8D8D8;\n  border-radius: 2px;\n  color: #282c34;\n  overflow-y: scroll;\n  opacity: 0;\n  transform-origin: top;\n  transform: translateY(-10px);\n  transition: transform 0.2s ease-out, opacity 0.2s ease-out; }\n\n.styles__containerActive___1dUVR {\n  transform: translateY(0px);\n  opacity: 1; }\n\n.styles__contentContainer___1ubgH {\n  padding: 10px 20px 20px 20px; }\n\n.styles__header___1-i53 {\n  margin-bottom: 15px; }\n\n.styles__headerTitle___Srlqu {\n  margin: 0 0 -4px 0;\n  text-align: left;\n  margin-right: 10px; }\n\n.styles__headerSubTitle___1Mu0E {\n  font-size: 0.95em; }\n\n.styles__aboutText___3rLT7 {\n  margin: 8px 0; }\n", ""]);

// exports
exports.locals = {
	"container": "styles__container___1l0SO",
	"containerActive": "styles__containerActive___1dUVR",
	"contentContainer": "styles__contentContainer___1ubgH",
	"header": "styles__header___1-i53",
	"headerTitle": "styles__headerTitle___Srlqu",
	"headerSubTitle": "styles__headerSubTitle___1Mu0E",
	"aboutText": "styles__aboutText___3rLT7"
};