exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__videoContainer___1Lzhv {\n  max-width: 640px;\n  height: auto;\n  margin: 0 auto 30px auto; }\n\n.styles__projectImage___J_jiW {\n  width: 100%;\n  height: auto; }\n\n.styles__subtext___2rZPJ {\n  font-size: 0.8em;\n  margin: 0 0 40px 0;\n  font-style: italic;\n  text-indent: 20px; }\n\n.styles__subtextDescription___5Ue_P {\n  font-size: 0.8em;\n  margin: 0 0 10px 0;\n  font-style: italic;\n  text-indent: 20px; }\n\n.styles__textBlock___1FFE7 {\n  font-size: 0.9em;\n  margin: 14px 0; }\n\n.styles__textBlockCaption___2qCcR {\n  margin: 5px 0 30px 0; }\n\n.styles__italic___2fEW_ {\n  font-style: italic; }\n\nul {\n  margin: 0;\n  padding-left: 20px; }\n\na {\n  text-decoration: underline; }\n", ""]);

// exports
exports.locals = {
	"videoContainer": "styles__videoContainer___1Lzhv",
	"projectImage": "styles__projectImage___J_jiW",
	"subtext": "styles__subtext___2rZPJ",
	"subtextDescription": "styles__subtextDescription___5Ue_P",
	"textBlock": "styles__textBlock___1FFE7",
	"textBlockCaption": "styles__textBlockCaption___2qCcR",
	"italic": "styles__italic___2fEW_"
};