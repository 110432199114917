exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__back___1TFKL {\n  display: block;\n  width: 0;\n  height: 0;\n  cursor: pointer; }\n\n.styles__backActive___3R6TN {\n  width: 26px;\n  height: 30px; }\n\n.styles__arrow___1kpCd {\n  width: 30px;\n  height: 2px;\n  display: inline-block;\n  background-color: #abb2bf;\n  border-radius: 4px;\n  position: absolute;\n  left: 0;\n  top: 18px;\n  opacity: 0;\n  transition: transform 0.15s ease-in-out, opacity 0.15s ease-in-out; }\n\n.styles__arrowTop___e6FQh {\n  width: 20px;\n  left: 1px;\n  transform-origin: left;\n  transform: rotate(0deg); }\n\n.styles__arrowTopActive___G0duB {\n  transform: rotate(-45deg);\n  opacity: 1; }\n\n.styles__arrowBottom___E4FYX {\n  width: 20px;\n  left: 1px;\n  transform-origin: left;\n  transform: rotate(0deg); }\n\n.styles__arrowBottomActive___3Fvdf {\n  transform: rotate(45deg);\n  opacity: 1; }\n\n.styles__arrowCenter___37zlr {\n  opacity: 0; }\n\n.styles__arrowCenterActive___KbiXg {\n  opacity: 1; }\n", ""]);

// exports
exports.locals = {
	"back": "styles__back___1TFKL",
	"backActive": "styles__backActive___3R6TN",
	"arrow": "styles__arrow___1kpCd",
	"arrowTop": "styles__arrowTop___e6FQh",
	"arrowTopActive": "styles__arrowTopActive___G0duB",
	"arrowBottom": "styles__arrowBottom___E4FYX",
	"arrowBottomActive": "styles__arrowBottomActive___3Fvdf",
	"arrowCenter": "styles__arrowCenter___37zlr",
	"arrowCenterActive": "styles__arrowCenterActive___KbiXg"
};