exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__container___Q8_m7 {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-around; }\n\n.styles__header___2VROl {\n  padding: 10px 0 20px 0; }\n\n.styles__headerTitle___29W7N {\n  margin: 0 0 10px 0; }\n\n.styles__headerSubTitle___1CLoy {\n  text-align: center; }\n\n.styles__projectThumbnail___1hTkM {\n  border: 2px solid black;\n  width: 220px;\n  height: 220px;\n  margin: 20px;\n  cursor: pointer;\n  color: #FAFAFA;\n  display: flex;\n  flex-flow: column;\n  flex-wrap: nowrap;\n  justify-content: space-between; }\n\n.styles__projectLabel___D-7_C {\n  background-color: rgba(255, 255, 255, 0.6);\n  color: #000;\n  font-size: 1.4em;\n  font-weight: bold;\n  margin: 0;\n  padding: 8px; }\n\n.styles__projectDescription___3oRuh {\n  background-color: rgba(32, 32, 32, 0.5);\n  margin: 0;\n  padding: 8px;\n  color: #FAFAFA; }\n", ""]);

// exports
exports.locals = {
	"container": "styles__container___Q8_m7",
	"header": "styles__header___2VROl",
	"headerTitle": "styles__headerTitle___29W7N",
	"headerSubTitle": "styles__headerSubTitle___1CLoy",
	"projectThumbnail": "styles__projectThumbnail___1hTkM",
	"projectLabel": "styles__projectLabel___D-7_C",
	"projectDescription": "styles__projectDescription___3oRuh"
};