import React from 'react';
import styles from './styles.scss';

const Component = () => (
  <article>
    <h1>Bezier Curves</h1>
    <p className={styles.textBlock}>Created with Java Swing</p>

    <img className={styles.projectImage} src="assets/images/projects/bezierCurves/01_medium.jpg" />
    <p className={styles.subtext}>Figure 47687f1.</p>

    <img className={styles.projectImage} src="assets/images/projects/bezierCurves/08_medium.jpg" />
    <p className={styles.subtext}>Figure 8ae2efa.</p>

    <img className={styles.projectImage} src="assets/images/projects/bezierCurves/04_medium.jpg" />
    <p className={styles.subtext}>Figure 69b88fe.</p>

    <img className={styles.projectImage} src="assets/images/projects/bezierCurves/07_medium.jpg" />
    <p className={styles.subtext}>Figure 1b3ac73.</p>

    <img className={styles.projectImage} src="assets/images/projects/bezierCurves/03_medium.jpg" />
    <p className={styles.subtext}>Figure 4012715.</p>

    <img className={styles.projectImage} src="assets/images/projects/bezierCurves/09_medium.jpg" />
    <p className={styles.subtext}>Figure 2d0d58a.</p>

    <img className={styles.projectImage} src="assets/images/projects/bezierCurves/10_medium.jpg" />
    <p className={styles.subtext}>Figure e36b3eb.</p>

    <img className={styles.projectImage} src="assets/images/projects/bezierCurves/02_medium.jpg" />
    <p className={styles.subtext}>Figure 0d7e632.</p>

    <img className={styles.projectImage} src="assets/images/projects/bezierCurves/06_medium.jpg" />
    <p className={styles.subtext}>Figure 8934f7b.</p>

    <img className={styles.projectImage} src="assets/images/projects/bezierCurves/05_medium.jpg" />
    <p className={styles.subtext}>Figure 8934f7b.</p>
  </article>
);

export default Component;
