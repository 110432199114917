exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__container___GsCoo {\n  position: absolute;\n  left: 30px;\n  top: 60px;\n  width: calc(100% - 60px);\n  height: calc(100% - 90px);\n  background-color: #D8D8D8;\n  border-radius: 2px;\n  color: #282c34;\n  overflow-y: scroll;\n  opacity: 0;\n  transform-origin: top;\n  transform: translateY(20px);\n  transition: transform 0.2s ease-out, opacity 0.2s ease-out; }\n\n.styles__containerActive___2qGve {\n  transform: translateY(0px);\n  opacity: 1; }\n\n.styles__contentContainer___3Tb2R {\n  max-width: 900px;\n  margin: 0 auto;\n  padding: 10px; }\n", ""]);

// exports
exports.locals = {
	"container": "styles__container___GsCoo",
	"containerActive": "styles__containerActive___2qGve",
	"contentContainer": "styles__contentContainer___3Tb2R"
};