exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__buttonContainer___3xFAr {\n  position: absolute;\n  bottom: 0;\n  padding: 0 0 5px 8px; }\n\n.styles__button___Tu_7P {\n  color: #D8D8D8; }\n\n.styles__shuffleButton___bd0vU {\n  position: absolute;\n  bottom: 0;\n  left: 10px;\n  color: #D8D8D8;\n  width: 60px;\n  height: 60px;\n  cursor: pointer; }\n\n.styles__aboutButton___3e6e8 {\n  position: absolute;\n  bottom: 13px;\n  right: 10px;\n  cursor: pointer;\n  width: 50px;\n  height: 30px; }\n\n.styles__about___2YBpf {\n  position: absolute;\n  right: 30px;\n  bottom: 60px;\n  border-radius: 2px;\n  width: calc(100% - 60px);\n  max-height: calc(100% - 90px);\n  max-width: 360px;\n  overflow-y: scroll;\n  background-color: #D8D8D8;\n  opacity: 0;\n  transform: translateY(10px);\n  color: #282c34;\n  transition: transform 0.2s ease-out, opacity 0.2s ease-out; }\n\n.styles__aboutActive___3VxtV {\n  opacity: 1;\n  transform: translateY(0px); }\n\n.styles__aboutContent___jDHSX {\n  padding: 10px 20px; }\n", ""]);

// exports
exports.locals = {
	"buttonContainer": "styles__buttonContainer___3xFAr",
	"button": "styles__button___Tu_7P",
	"shuffleButton": "styles__shuffleButton___bd0vU",
	"aboutButton": "styles__aboutButton___3e6e8",
	"about": "styles__about___2YBpf",
	"aboutActive": "styles__aboutActive___3VxtV",
	"aboutContent": "styles__aboutContent___jDHSX"
};